import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, InputLabel, Snackbar} from '@mui/material';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import RecommendationModal from './RecommendationModal';
import generateGiftIdeas from '../services/generateGiftIdeas';
import CustomProgressBar from './CustomProgressBar';
import CookieManager from '../utils/CookieManager';
import TriesProgressBar from './TriesProgressBar';
import AnimatedPlaceholderTextField from './AnimatedPlaceholderTextField';

function GiftRecommendationForm() {
  const theme = useTheme();
  const [description, setDescription] = useState('');
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const cookieManager = new CookieManager();
  let triesUsed = cookieManager.getTriesCount();
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!description.trim()) {
      setErrorMessage("We're all ears! What's the gift for?");
      setSnackbarOpen(true);
      return;
    }

    setIsLoading(true);
  
    try {
      const response = await generateGiftIdeas(description);
      const fetchedProducts = JSON.parse(response);
      console.log("Fetched products:", fetchedProducts);
      setRecommendedProducts(fetchedProducts);
      setModalOpen(true);

      // Update the tries count upon successful generation
      cookieManager.incrementTriesCount();
      triesUsed = cookieManager.getTriesCount(); 
    } catch (error) {
      console.error('Error fetching gift ideas:', error);
      setErrorMessage("Error fetching gift ideas. Try again!");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };


  
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '0.1%', marginBottom: '1%', width: '100%', maxWidth: '100%' }}>
      <TriesProgressBar triesUsed={triesUsed} totalTries={10} />
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '100%' }}>
      <InputLabel htmlFor="description" sx={{ fontSize: { xs: '0.9rem', sm: '1.8rem', md: '2.5rem' }, marginBottom: '10px', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: { xs: '1px', sm: '2px', md: '3px' }, padding: { xs: '5px 10px', sm: '8px 15px', md: '10px 20px' }, color: 'black' }}>Tell Us About the Person & Your Budget</InputLabel>
      <AnimatedPlaceholderTextField
        description={description} 
        setDescription={setDescription} 
      />
        <Button sx={{ backgroundColor: theme.palette.primary.main, '&:hover': { backgroundColor: theme.palette.secondary.main, }, borderRadius: 20, padding: '10px 20px', }} variant="contained" startIcon={<CardGiftcardIcon />} type="submit">
        Find the Perfect Gift
        </Button>
      </form>
      {isLoading && (
        <Box sx={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000, }}>
          <CustomProgressBar />
        </Box>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={() => setSnackbarOpen(false)} message={errorMessage} />
      <RecommendationModal open={modalOpen} onClose={handleCloseModal} products={recommendedProducts} />
    </Box>
  );
}

export default GiftRecommendationForm;