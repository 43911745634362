import React from 'react';
import { LinearProgress, Typography, Box } from '@mui/material';

function CustomProgressBar() {
  return (
    <Box sx={{
        position: 'fixed', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: 'auto', 
        textAlign: 'center',
        padding: '20px',
        borderRadius: '15px', 
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000, 
      }}>
      <Typography variant="h6" gutterBottom style={{ color: 'white', fontWeight: 'bold' }}>
        Wait...Picking the best gifts!
      </Typography>
      <LinearProgress 
        style={{
          height: '10px',
          borderRadius: '5px',
          background: 'linear-gradient(to right, #f06292, #42a5f5)',
        }}
      />
    </Box>
  );
}

export default CustomProgressBar;
