import React from 'react';
import Product from './Product';

function ProductList({ products }) {
  return (
    <div>
      {products.map((product, index) => (
        <Product
          key={index}
          name={product.name}
          description={product.description}
          priceRange={product.priceRange}
        />
      ))}
    </div>
  );
}

export default ProductList;
