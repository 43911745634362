import React from 'react';
import ProductList from './ProductList';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function RecommendationModal({ open, onClose, products }) {
  const productCount = products.length;

  return (
    <Dialog 
      open={open} 
      fullWidth 
      maxWidth="md"
      disableBackdropClick // Keep this to prevent closing on backdrop click
      PaperProps={{
        sx: { 
          borderRadius: '15px',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <DialogTitle sx={{
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        color: '#3f51b5',
        borderBottom: '1px solid #ddd',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        Recommended Products ({productCount})
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowY: 'auto' }}>
        <ProductList products={products} />
      </DialogContent>
    </Dialog>
  );
}

export default RecommendationModal;
