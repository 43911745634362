import React from 'react';
import { Box, Typography } from '@mui/material';

function TriesProgressBar({ triesUsed, totalTries = 10 }) {
  const segments = Array.from({ length: totalTries }, (_, index) => (
    <div
      key={index}
      style={{
        flex: 1,
        marginRight: index < totalTries - 1 ? '2px' : 0,
        height: '15px',
        borderRadius: '5px',
        backgroundColor: index < totalTries - triesUsed ? '#3f51b5' : 'transparent',
        border: '1px solid #3f51b5',
      }}
    />
  ));

  return (
    <Box sx={{ 
        width: { xs: '80%', sm: '75%', md: '50%' }, // Adjusted width for mobile
        textAlign: 'center', 
        margin: '20px 0' 
      }}>
      <Typography variant="subtitle2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}>
        Tries Left: {totalTries - triesUsed}/{totalTries}
      </Typography>
      <div style={{ display: 'flex', height: '15px', borderRadius: '10px' }}>
        {segments}
      </div>
    </Box>
  );
}

export default TriesProgressBar;
