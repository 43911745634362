import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

function AnimatedPlaceholderTextField({ description, setDescription }) {
    const [animatedPlaceholder, setAnimatedPlaceholder] = useState('');
    const exampleText = "A Christmas gift under $100 for my sister who enjoys gardening and cooking.";
    const index = useRef(0); // Using useRef instead of a standard variable
  
    useEffect(() => {
      if (description === '') {
        const timer = setInterval(() => {
          setAnimatedPlaceholder(prev => prev + (exampleText[index.current] || ''));
          index.current++;
          if (index.current > exampleText.length) clearInterval(timer);
        }, 75); // Adjust typing speed here
  
        return () => clearInterval(timer);
      }
    }, [description]);

  return (
    <TextField 
      id="description"
      sx={{ 
        width: { xs: '90%', sm: '80%', md: '65%' },
        maxWidth: '100%',
        marginBottom: '20px',
        backgroundColor: 'black',
        borderRadius: 10,
        '& .MuiOutlinedInput-root': { 
          borderRadius: 10, 
          borderColor: 'rgba(255, 69, 120, 0.8)', 
          border: 'none !important', 
          '& fieldset': { 
            borderColor: 'rgba(255, 69, 120, 0.8)', 
          }, 
          '&:hover fieldset': { 
            borderColor: 'theme.palette.primary.main', 
          }, 
          '&.Mui-focused fieldset': { 
            borderColor: 'theme.palette.primary.main', 
          }, 
          outline: 'none', 
        }, 
        '& .MuiInputBase-input': { 
          fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }, // Responsive font size
          padding: '10px', 
          lineHeight: '2.5rem', 
          color: 'white', 
        } 
      }}
      variant="outlined"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      placeholder={description === '' ? animatedPlaceholder : ''}
      multiline 
      rows={8}
      inputProps={{ maxLength: 250 }}
    />
  );
}

export default AnimatedPlaceholderTextField;
