import Cookies from 'js-cookie';

class CookieManager {
  constructor() {
    this.cookieName = 'giftRecommendationTries';
  }

  getTriesCount() {
    return parseInt(Cookies.get(this.cookieName) || '0');
  }

  incrementTriesCount() {
    const tries = this.getTriesCount();
    const currentMonth = new Date().getMonth();
    Cookies.set(this.cookieName, tries + 1, { expires: new Date(new Date().getFullYear(), currentMonth + 1, 1) });
  }

  isLimitReached() {
    return this.getTriesCount() >= 10;
  }

  resetTriesCount() {
    Cookies.remove(this.cookieName);
  }
}

export default CookieManager;
