import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import GiftRecommendationForm from './components/GiftRecommendationForm';
import Header from './components/Header'; 

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<GiftRecommendationForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
