import React from 'react';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import { FaAmazon} from 'react-icons/fa';
import { Button } from '@mui/material';

function Product({ name, description, priceRange }) {
  const encodedName = encodeURIComponent(name);
  const encodedAmazonAffiliateTag = encodeURIComponent('brainybrick-20');
  const googleSearchUrl = `https://www.google.com/search?q=${encodedName}`;
  const amazonSearchUrl = `https://www.amazon.com/s?k=${encodedName}&tag=${encodedAmazonAffiliateTag}`;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: 'white',
        padding: '15px',
        margin: '10px 0',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      }}
    >
      <div style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '10px' }}>
        {name}
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Description:</strong> {description}
      </div>
      <div style={{ marginBottom: '10px' }}>
        <strong>Price Range:</strong> {priceRange}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton color="primary" aria-label="Search on Google" href={googleSearchUrl} target="_blank">
          <LaunchIcon />
        </IconButton>
        <Button 
          variant="contained" 
          href={amazonSearchUrl} 
          target="_blank" 
          endIcon={<FaAmazon style={{ color: 'black' }} />}
          style={{ 
            backgroundColor: '#FFA500', 
            color: 'black', 
            borderRadius: '20px', 
            textTransform: 'none', 
            padding: '6px 12px', 
            marginLeft: '10px'
          }}
        >
          View at Amazon
        </Button>


      </div>
    </div>
  );
}

export default Product;
