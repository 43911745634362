import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_SERVER_URL; 

async function generateGiftIdeas(prompt) {
  try {
    const response = await axios.post(`${SERVER_URL}/generate-gifts`, { prompt }, {
      responseType: 'json' 
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching gift ideas:', error);
    throw error;
  }
}

export default generateGiftIdeas;
