import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import logo from '../assets/app_logo.svg';

function Header() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: 'rgba(255, 192, 203, 0)',
        color: 'black',
      }}
    >
      <Toolbar
        sx={{
          padding: { xs: '5px 0', sm: '10px 0' }, // Reduced padding for xs and sm screens
          // Ensure other styles remain unchanged
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            cursor: 'pointer',
          }}
          onClick={handleLogoClick}
        >
          <img 
            src={logo} 
            alt="Logo" 
            style={{ 
              height: '72px', 
              marginRight: '8px',
            }} 
            sx={{
              height: { xs: '48px', sm: '60px', md: '72px' } // Responsive logo height
            }}
          />
          <Typography 
            variant="h6" 
            sx={{ 
              color: 'black', 
              fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' } // Responsive font size
            }}
          >
            PersoniGifter
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
